.padding{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 7%;

}
.headsabout {
    font-family: NeueWorld-CondensedRegular;
    font-size: 30px;
    font-weight: bold;
    color: #040404;
}
.headsaboutt {
    font-family: NeueWorld-CondensedRegular;
    font-size: 30px;
    font-weight: bold;
    color: #040404;
    margin-top: 7%;
}
.liabout{
    
    font-family:sans-serif;
    font-size: 12px;
  
}


/* wedo */

.processmain{
    margin-left: 10%;
    margin-right: 10%;
}
.whyUsGrid {
    max-width: 1500px;
     margin: 0 auto;
     display: grid;
     grid-gap: 1rem;
     padding: 32px 5%;
   }
     
   @media (min-width: 370px) {
     .whyUsGrid {
       grid-template-columns: repeat(1, 1fr);
     }
   }
     
   @media (min-width: 900px) {
       .whyUsGrid {
         grid-template-columns: repeat(3, 1fr);
       }
     }
   
     .whyUsCard {
       background-color:white;
       box-shadow: 0 4px 8px 0 rgba(66, 64, 64, 0.39);
       transition: 0.3s;
       border-radius: 5px;
       display: flex;
       border: 0.1rem solid rgba(0, 0, 0, 0.1);
       padding-left: 24px;
       flex-direction: column;
       padding: 2%;
       border: 2px solid black;
       align-items: center;
       box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
     }
     .whyUsCard:hover {
       box-shadow: 0 8px 16px 0 rgba(7, 6, 6, 0.5);
     }
     
     .name {
     font-family: "Open Sans", sans-serif;
      text-align: center;
      font-weight: bolder;
      font-size: medium;
      font-size: 30px;
      color: rgb(5, 5, 5);
    }
       
      .year{
       color: rgb(0, 0, 0);
       font-family: "Open Sans", sans-serif;
      text-align: center;
       font-size: 13px;
       margin-top: 10px;
       font-weight: bold;
      }
      .buttontop{
       background-color: white;
       margin-top: 10px;
      text-align: center;
      font-size: 12px;
      margin-bottom: 15px;
      }
      .buttonbottom{
       background-color: white;
       padding: 10px;
      }
      .buttonbox{
       background-color: white;
   }
   .headss {
   
    font-family: NeueWorld-CondensedRegular;
    font-size: 50px;
    font-weight: bold;
    color: #040404;
}


/* process */



.processmain{
    margin-left: 10%;
    margin-right: 10%;
}
.processGrid {
    max-width: 1500px;
     margin: 0 auto;
     display: grid;
     grid-gap: 1rem;
     padding: 32px 5%;
   }
     
   @media (min-width: 370px) {
     .processGrid {
       grid-template-columns: repeat(1, 1fr);
     }
   }
     
   @media (min-width: 900px) {
       .processGrid {
         grid-template-columns: repeat(4, 1fr);
       }
     }
   
     .processCard {
       background-color:white;
       box-shadow: 0 4px 8px 0 rgba(66, 64, 64, 0.39);
       transition: 0.3s;
       border-radius: 5px;
       display: flex;
       border: 0.1rem solid rgba(0, 0, 0, 0.1);
       padding-left: 24px;
       flex-direction: column;
       padding: 2%;
       border: 2px solid black;
       align-items: center;
       box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
     }
     .processCard:hover {
       box-shadow: 0 8px 16px 0 rgba(7, 6, 6, 0.5);
     }
     
     .processname {
     font-family: "Open Sans", sans-serif;
      text-align: center;
      font-weight: bolder;
      font-size: medium;
      font-size: 30px;
      color: rgb(5, 5, 5);
    }
       
      .processyear{
        color: rgb(0, 0, 0);
        font-family: "Open Sans", sans-serif;
       text-align: center;
        font-size: 13px;
        margin-top: 10px;
        font-weight: bold;
      }
      .processbuttontop{
       background-color: white;
       margin-top: 10px;
       margin-bottom: 10px;
      text-align: center;
      font-family: "Open Sans", sans-serif;
    
      }
      .processbuttonbottom{
       background-color: white;
       padding: 10px;
      }
      .processbuttonbox{
       background-color: white;
   }
   .processheadss {
   
    font-family: NeueWorld-CondensedRegular;
    font-size: 50px;
    font-weight: bold;
    color: #040404;
}

/* value */

.HomeLandingBoxvalue {
    max-width: 2000px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    padding: 32px 5%;
}

@media (min-width: 300px) {
    .HomeLandingBoxvalue {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 900px) {
    .HomeLandingBoxvalue {
        grid-template-columns: repeat(2, 1fr);
    }
}

.HomeLandingMainBoxvalue {
    margin-top: 8%;
    margin-left: 10%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.HomeLandingimgBoxvalue {
    display: flex;
    margin-right: 10%;
    flex-direction: column;
    align-items: flex-start;
}

.HomePageTextTwovalue {
    color: #1d2533;
    text-align: left;
    align-items: flex-start;
    font-size: 20px;
    font-family: sans-serif;
    margin-top: 12%;
}

.headsvalue {
    font-family: NeueWorld-CondensedRegular;
    font-size: 35px;
    font-weight: bold;
    color: #040404;
}

.missiononevalue {
    background-color: #040404;
    height: 100%;
    width: 100%;
}

.missiontwovalue {
    margin-left: 25%;
    font-family: sans-serif;
    background-color: white;
    margin-right: 25%;
    color: #050607;
    font-size: 20px;
    font-weight: 345;
    line-height: 28px;
    margin-bottom: 10px;
    padding: 3%;
}

.achivevalue {
    font-family: sans-serif;
    font-size: 20px;
    margin-bottom: 50px;
}

.missionthreevalue {
    margin-left: 21%;
    padding: 30px;
    background-color: #040404;
    color: white;
}

.missionvalue {
    margin-left: 34%;
    font-family: NeueWorld-CondensedRegular;
    font-size: 25px;
    font-weight: bold;
}

.missionnvalue {
    font-family: NeueWorld-CondensedRegular;
    font-size: 25px;
    font-weight: bold;
}