.Contact{
 margin-top: 7%;
  margin-left: 15%;
  margin-right: 15%;
  }
  .HomeLandingBox {
      max-width: 2000px;
      margin: 0 auto;
      display: grid;
      grid-gap: 1rem;
  
   
    }
    
    @media (min-width: 300px) {
      .HomeLandingBox {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    
    @media (min-width: 900px) {
      .HomeLandingBox {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    
    .HomeLandingMainBox {
  
      display: flex;
    
      flex-direction: column;
      align-items: flex-start;
    }
    
    .HomeLandingimgBox {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
   
    }
    .link{
      margin-top: 40%;
      margin-bottom: 10%;
    }
    .linkItem {
  margin-top: 4%;
      color: black;
      font-size: 12px;
      font-weight: 420;
  
    
      text-decoration: none;
      cursor: pointer;

    }
    .linkItem:hover {
      text-decoration: underline;
      text-decoration-color: black;

    }

    
  
  
  
  
  
  .achive{

    font-size: 12px;
    margin-bottom: 20px;
  }
  
  
  
  
  
    .mainBox {
   
      color: white;
     
    }
    
    .heads {
      margin-top: 40%;
      font-family: NeueWorld-CondensedRegular;
      font-size: 30px;
      font-weight: bold;
      color: #040404;
  }
  .headss {
     
      font-family: NeueWorld-CondensedRegular;
      font-size: 30px;
      font-weight: bold;
      color: #040404;
      margin-top: 7%;
  }
   
   
  
  
  
  