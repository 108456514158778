.Practice {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 7%;
}

.mainBox {
  color: white;
}

.heading {
  font-size: 12px;
  font-weight: 700px;
  margin-top: 24px;
  font-style: bold;
  color: black;
}

.headingt {
  margin-top: 24px;
  font-weight: 700px;
  font-size: 17px;
  font-style: bold;
  color: rgb(255, 255, 255);
}

.headingt:hover {
  text-decoration: underline;
  text-decoration-color: white;
  color: white;
}

.headingm {
  margin-bottom: 20px;
  font-size: 24px;
  margin-top: 74px;
  font-style: bold;
  font-weight: 100px;
}

.topIconBox {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  max-width: 200px;
  cursor: pointer;
}

.lineBreak {
  margin-top: 2%;
  background-color: rgb(168, 166, 166);
  width: 100%;


  height: 1px;
}

.bottomBox {
  color: white;
  max-width: 2000px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}

@media (min-width: 300px) {
  .bottomBox {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 600px) {
  .bottomBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .bottomBox {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 600px) {
  .bottomBox {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 600px) {
  .bottomBox {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 600px) {
  .bottomBox {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 600px) {
  .bottomBox {
    grid-template-columns: repeat(7, 1fr);
  }
}

.linkItem {
  margin-right: 0;
  color: black;
  cursor: pointer;
  font-size: 12px;
  margin-top: 3px;
}

.whyUsGrid {
  max-width: 2000px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  margin-top: 30px;

}

@media (min-width: 400px) {
  .whyUsGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 900px) {
  .whyUsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.whyUsCard {
  box-shadow: 0 4px 8px 0 rgba(66, 64, 64, 0.39);
  transition: 0.3s;
  border-radius: 8px;
  display: flex;
  background-color: white;

  flex-direction: column;

}

.whyUsCard:hover {
  box-shadow: 0 8px 16px 0 rgba(7, 6, 6, 0.5);
}










.practiceimgbox {
  display: flex;
  flex-direction: row;
}