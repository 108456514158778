.showGrid {
    max-width: 2000px;
    margin: 0 auto;
    display: grid;
    grid-gap: 4rem;
    padding: 32px 5%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
  }
  
  @media (min-width: 400px) {
    .showGrid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media (min-width: 900px) {
    .showGrid {
      grid-template-columns: repeat(2, 2fr);
    }
  }
  
  .showCard {
    transition: 0.3s;
  
    display: flex;
  border: 2px solid white;
    flex-direction: column;
  
  }
  
  .showmore {
    margin-top: 2px;
    color: black;
    font-weight: bold;
    text-decoration: underline;
    padding: 0 10px;
    line-height: 2.3em;
    border: 1px solid white;
    background-color: white;
  }
  
  .showmore:hover {
    color: rgb(82, 79, 79);
  }
  
  .Home {
    margin-left: 15%;
    margin-right: 15%;
  }
  
  .overlayer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .image {
    background-color: #2f5596;
  }
  
  .imgeffect {
    color: white;
    font-size: 18px;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
  }
  .heads {
    font-family: NeueWorld-CondensedRegular;
    font-size: 22px;
    font-weight: bold;
    color: #040404;
    margin-top: 7%;
  text-align: center;
margin-left: 2%;
margin-right: 2%;
}
.sliding{
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 10%;

 
}