.Mainbox {
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    flex-direction: row;
}
.menubox {
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.linkItem {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    font-style: italic;
    line-height: 20px;
}
.linkItem:hover {
    text-decoration: underline;
    text-decoration-color: white;
    color: rgb(105, 103, 103);
}