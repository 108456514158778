.press {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 5%;
}

.button {
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgb(197, 50, 50);
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2%;
  font-size: 15px;
}

.head {
  color: rgb(197, 50, 50);
}

/* part3*/
.card {
  transition: 0.3s;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: white;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(253, 114, 114, 0.5);
}

.packagesGrid {
  max-width: 2000px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;

}

@media (min-width: 500px) {
  .packagesGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.packageCard {
  transition: 0.3s;
  border-radius: 8px;
  display: flex;
  background-color: white;

  flex-direction: column;
}

.packageNameHead {
  flex-direction: row;
  background-color: white;
  display: flex;

}

.packageName {
  font-weight: 700;
  color: rgb(51, 93, 182);
}

.linkItem {
  margin-right: 0;
  color: black;
  cursor: pointer;
  font-size: 12px;
}

.imgmainbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
}

.imgright {
  width: 50%;
}